/* Navbar Top Border Css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.top-border {
  width: 100%;
  height: 15px;
  background-color: #A72529;
}

.navbar-items {
  width: 100%;

  height: 82px;


  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.nav-bar-tabs {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: auto;
}

.navbar-navli-links ul li {
  display: inline-block;
  list-style: none;

}

.navbar-navli-links ul li a {
  text-decoration: none;
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  transition: all 0.5s ease-in-out;
  margin: 2rem;


}



.navbar-navli-links ul li a:active {
  color: #A72529;
  border-bottom: 6px solid;
  border-width: 6px;


}

.btn-search button {
  display: flex;
  width: 106px;
  justify-content: center;
  align-items: center;
  gap: 17px;
  color: #000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 1rem;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: normal;
}

.navigation ul {
  list-style: none;
  display: flex;
  /* Add styling for your navigation */
}

.navigation li {
  padding: 10px;
  cursor: pointer;
}

.navigation li a.active {
  /* Apply styling for active menu item */
  color: #A72529 !important;
  border-bottom: #A72529 solid 2px;
  border-width: 2px;

}

.Serach {
  width: 400px;
}

@media only screen and (max-width: 971px) {
  .hEADER-MAIN {
    display: none;
  }
}