
.Navbara{
   display: none;
  
}
.navlogoa{
    height: 45px;
    width: 130PX;
    margin-top: 5px;
}
.leftside{
    width:60% ;
   
}
.rightside{
    width:40% ;
    text-align: right;
    justify-content: right;
    display: flex;
   
}
.toggle{
    width: 34px;
    height: 34px;
margin-top: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.listdiv{
    margin-left: 10px;
    border-left: 1px solid lightgray;
    padding-left: 20px;
}
.option{
    border-bottom: 1px solid #A72529;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
}
@media only screen and (max-width: 976px) {
    .Navbara{
        width: 100%;
        background-color: white;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left:15px ;
        padding-right: 15px;
        padding-top: 5px;
    }
  }