
.main{
    background-color: #000;
   
}
/* styles.css or your CSS file */
.active-link {
    color: red;
    /* Add any other styles you want for the active link */
  }
/* Add this CSS to your stylesheet or a style module */
.scroll-to-top-button {
    /* Your default button styles here */
    color: white;
  }
  
  .scroll-to-top-button.active {
     /* Change this to your desired red color */
    color: #A72529; /* Change this to your desired text color */
  }
  
.footerlogo{
    width: 220px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.foopara{
    color: #DBDBDB;
    text-align: left;
}
.footericons{
    height: 25px;

}
.icondiv{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
   
}
.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.iconinfo{
   display: flex;
   flex-direction: column;
   padding-left: 15px;
}
.listitem{
    color: #DBDBDB;
    text-decoration: none;
    list-style: none;
   margin-top: 60px;
   margin-bottom: 30px;
}
.li:hover{
    color: #A72529;
   cursor: pointer;
}
.foohead{
    font-weight: 700;
}
.cont-main{
    border-bottom: 1px solid  #DBDBDB;
}
.foobottom{
    color: #DBDBDB;
    display: flex;
    flex-direction: row;
    line-height: 60px;
}
.half{
    width: 55%;
  text-align: left;
}
.half span{
 margin-right: 20px;
}
.half1{
    width: 45%;
    text-align: right;
}


@media only screen and (max-width: 600px) {
    .icondiv{
        min-width: 170px;
    }
    .foobottom{
        color: #DBDBDB;
        display: flex;
        flex-direction:column;
        line-height: 60px;
    }
    .half1{
        width: 100%;
        text-align: left;
    }
    .half{
        width: 100%;
      text-align: left;
    }
    .half span{
        margin-right: 33px;
       }
       .listitem{
        color: #DBDBDB;
        text-decoration: none;
        list-style: none;
       margin-top: 60px;
       margin-bottom: 30px;
      margin-left: -10px;
    }
  }