
.ext{
    margin-bottom: 5%;
}


.clas{
    margin-top: 10px !important;
}

@media only screen and (max-width: 600px) {
    .ext{
        margin-bottom: 20%;
    }
    .clas{
        margin-top: 150px !important;
    }
  }