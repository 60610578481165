.main-product {
    background-color: #EEEEEE;
    padding-top: 50px;
    padding-bottom: 60px;
}

.underline {
    border-bottom: 5px solid #A72529;
    padding-bottom: 15px;
}

.Product-head-01 {
    color: #141414;
    font-size: 34px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}

.ppara {
    font-size: 20px;
    color: #141414;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 30px;
}

.cardhead {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    margin: 20px;
}

.cardimg {
    height: 160px;
    width: 160px;
}

@media only screen and (max-width: 600px) {
    .underline {
        border-bottom: 3px solid #A72529;
        padding-bottom: 0px;
    }
}