* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-us {
    width: 100%;
    height: 400px;
    background-image: url(../assets/Contact_us_bg.png);
    background-repeat: no-repeat;
    background-size: cover;


}

.moo {
    height: 370px;
    width: 440px;
}

.pclass {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 17px;
}

.contact-us-section {
    display: flex;
    flex-direction: column;

    width: 100%;
    align-items: center;
    padding-top: 1rem;
    height: 100%;
}

.contact-us-head h1 {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Teko', sans-serif;
    font-size: 64px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    height: 70px;
    margin-top: 10px;

}

.get-in-touch {
    color: #A25254;
    font-family: 'Teko', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contact-us-para {
    color: #FFF;


    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

}

.contact-form {
    padding: 2rem;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-self: center;
    margin: auto;
    background: #FFF;
    margin-top: -300px;
    margin-bottom: 100px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 63px 0px rgba(0, 0, 0, 0.05), 0px 188px 75px 0px rgba(0, 0, 0, 0.01), 0px 293px 82px 0px rgba(0, 0, 0, 0.00);
}

.contact-form1 {
    padding: 2rem;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-self: center;
    margin: auto;
    background: #FFF;
    margin-top: -250px;
    margin-bottom: 100px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 63px 0px rgba(0, 0, 0, 0.05), 0px 188px 75px 0px rgba(0, 0, 0, 0.01), 0px 293px 82px 0px rgba(0, 0, 0, 0.00);
}
.int3{
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    padding-left: 10px;
}
.int3:focus{
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    padding-left: 10px;
    outline: none;
}
.inpt1 {
    border: 1px solid #D0D5DD;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
}
.inpt11 {
    border: 1px solid #D0D5DD;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
}
.sentbtn{
    width: 100%;
    height: 50px;
    color: white;
    background-color: #833336;
    border: none;
    border-radius: 10px;
}
.GetToucuh {
    width: 50%;
    height: 100%;
    background-color: #FFF;
}

.Contact-us {
    width: 50%;
    height: 100%;

}

.From-Main {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    justify-content: start;
    padding: 1rem;
}

.Name_input {

    width: 495px;
    padding: 15px 356px 16px 18px;
    align-items: center;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.Email_input {
    margin-top: 3%;
    width: 495px;
    padding: 15px 293px 16px 18px;
    align-items: center;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.Text-Message {
    display: flex;
    width: 495px;
    height: 134px;
    padding: 14px 324px 101px 18px;
    align-items: center;
    border: 1px solid #D0D5DD;
    background: #FFF;
    margin-top: 3%;
}

.Btn_Submit {
    display: flex;
    width: 400px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-600, #7F56D9);
    background: #833336;
    color: var(--white, #FFF);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 12%;
    line-height: 24px;
}

.Icons-Divs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin: 1rem;
}

.contact-us-para-2 {
    color: #667085;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;


    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.get-inTousc-0102 {
    margin-top: 10%;
}

.icons-text {
    color: #1F1F1F;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 1024px) {
    .contact-form {

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;




        background: #FFF;


        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 63px 0px rgba(0, 0, 0, 0.05), 0px 188px 75px 0px rgba(0, 0, 0, 0.01), 0px 293px 82px 0px rgba(0, 0, 0, 0.00);
    }
    .contact-form1 {

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;


margin-top: -200px;

        background: #FFF;


        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 63px 0px rgba(0, 0, 0, 0.05), 0px 188px 75px 0px rgba(0, 0, 0, 0.01), 0px 293px 82px 0px rgba(0, 0, 0, 0.00);
    }
    .GetToucuh {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

    }

    .contact-form {

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;






        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 63px 0px rgba(0, 0, 0, 0.05), 0px 188px 75px 0px rgba(0, 0, 0, 0.01), 0px 293px 82px 0px rgba(0, 0, 0, 0.00);
    }

    .GetToucuh {
        width: 100%;
        height: 100%;
        background-color: #FFF;
    }
.center{
    text-align: center;
}
    .Contact-us {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

}

@media only screen and (max-width: 1024px) {
    .contact-form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        justify-content: center;

        margin: auto;
        background: #FFF;
        margin-top: -300px;

        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 63px 0px rgba(0, 0, 0, 0.05), 0px 188px 75px 0px rgba(0, 0, 0, 0.01), 0px 293px 82px 0px rgba(0, 0, 0, 0.00);
    }

    .GetToucuh {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .From-Main {
        display: flex;
        flex-direction: column;
        width: 100%;

        height: 100%;
        justify-content: center;

    }


    .Email_input {
        margin-top: 3%;
        width: 100%;
        padding: 15px 293px 16px 18px;
        align-items: center;
        border: 1px solid #D0D5DD;
        background: #FFF;
    }

    .Text-Message {
        display: flex;
        width: 100%;
        height: 134px;
        padding: 14px 324px 101px 18px;
        align-items: center;
        border: 1px solid #D0D5DD;
        background: #FFF;
        margin-top: 3%;
    }

    .Btn_Submit {
        display: flex;
        width: 100%;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--primary-600, #7F56D9);
        background: #833336;
        color: var(--white, #FFF);
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 12%;
        line-height: 24px;
    }

}


@media only screen and (max-width: 470px) {
    .GetToucuh {
        width: 100%;
        height: 100%;


    }

    .moo {
        height: 200px;
        width: 280px;
    }

    .pclass {
        text-align: center;
    }

    .Name_input {

        width: 100px !important;
        padding: 15px 356px 16px 18px;
        align-items: center;
        border: 1px solid #D0D5DD;
        background: #FFF;
    }

    .contact-us-para {
        color: #FFF;


        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 18px;
        font-style: normal;
        padding-left: 10px;
        margin-bottom: 30px;
        font-weight: 400;

    }
}