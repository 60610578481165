.section-Hero {
    background-image: url(../assets/Hero-Bg.png);
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    background-repeat: no-repeat;

    padding-bottom: 2rem;

}

.hero-section-content-main {
    width: 90%;
    margin: auto;
    height: 100%;

    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.hero-section-content-Text {

    width: 50%;
    height: 100%;
}

.hero-section-img-carousel {
    width: 50%;
    height: 100%;
    flex-direction: column;
    display: flex;

}

.crousel-right-back-arrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -10%;
}

.crousel-right-back-arrow button {
    display: flex;
    width: 53px;
    height: 43px;
    padding: 0px 2px 0px 1px;
    justify-content: center;
    align-items: center;
    background: var(--primary-color, #A72529);
    outline: none;
    border: none;
}

.Fron-Arow {
    background: #620609 !important;
}

.hero-section-img img {
    width: 100%;
}

.Hero-Section-head {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Hero-Section-head span {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Teko', sans-serif;
    font-size: 36px;
    font-style: normal;
    margin-top: 15%;
    font-weight: 500;
    line-height: normal;
}

.Main-Head {
    color: #FFF;
    text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Teko', sans-serif;
    font-size: 80px;
    line-height: 100%;
    font-style: normal;
    font-weight: 700;
    margin-top: 5%;

}

.HeroPara {
    color: #FFF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 10%;
    line-height: normal;
}

.Hero-button-main {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.shop-now {
    background: transparent;
    display: flex;
    padding: 9px 29px 8px 30px;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFF;
    color: #FFF;
    outline: none;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Contact-Us {
    display: flex;
    padding: 9px 26px 8px 26px;
    justify-content: center;
    align-items: center;
    background: #FFF;
    outline: none;
    color: #B04F53;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}

/* Media Quiers */
@media only screen and (max-width: 1280px) {
    .section-Hero {
        background-image: url(../assets/Hero-Bg.png);
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-repeat: no-repeat;
        background-size: auto;

    }

    .hero-section-content-main {
        width: 80%;
        margin: auto;
        height: 100%;

        display: flex;
        flex-direction: row;
        gap: 3rem;
    }
}

@media only screen and (max-width: 375px) {
    .section-Hero {
        background-image: url(../assets/Hero-Bg.png);
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-repeat: no-repeat;


    }

    .hero-section-content-main {
        width: 80%;
        margin: auto;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .Hero-Section-head span {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 30px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: normal;
    }

    .Main-Head {
        color: #FFF;
        text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 80px;
        line-height: 100%;
        font-style: normal;
        font-weight: 700;
        margin-top: -2%;

    }

    .hero-section-content-Text {

        width: 100%;
        height: 100%;
    }

    .hero-section-img-carousel {
        width: 100%;
        margin-top: 20%;
        height: 100%;
        flex-direction: column;
        display: flex;

    }

    .Hero-button-main {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 414px) {
    .section-Hero {
        background-image: url(../assets/Hero-Bg.png);
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-repeat: no-repeat;


    }

    .hero-section-content-main {
        width: 80%;
        margin: auto;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .Hero-Section-head span {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 30px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: normal;
    }

    .Main-Head {
        color: #FFF;
        text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 80px;
        line-height: 100%;
        font-style: normal;
        font-weight: 700;
        margin-top: -2%;

    }

    .hero-section-content-Text {

        width: 100%;
        height: 100%;
    }

    .hero-section-img-carousel {
        width: 100%;
        margin-top: 20%;
        height: 100%;
        flex-direction: column;
        display: flex;

    }

    .Hero-button-main {
        display: flex;
        flex-direction: column;
    }
}


@media only screen and (max-width: 820px) {
    .section-Hero {
        background-image: url(../assets/Hero-Bg.png);
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-repeat: no-repeat;


    }

    .hero-section-content-main {
        width: 80%;
        margin: auto;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .Hero-Section-head span {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 30px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: normal;
    }

    .Main-Head {
        color: #FFF;
        text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 80px;
        line-height: 100%;
        font-style: normal;
        font-weight: 700;
        margin-top: -2%;

    }

    .hero-section-content-Text {

        width: 100%;
        height: 100%;
    }

    .hero-section-img-carousel {
        width: 100%;
        margin-top: 20%;
        height: 100%;
        flex-direction: column;
        display: flex;

    }

    .Hero-button-main {
        display: flex;
        flex-direction: column;
    }
}


@media only screen and (max-width: 912px) {
    .section-Hero {
        background-image: url(../assets/Hero-Bg.png);
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-repeat: no-repeat;


    }

    .hero-section-content-main {
        width: 80%;
        margin: auto;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .Hero-Section-head span {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 30px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: normal;
    }

    .Main-Head {
        color: #FFF;
        text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 80px;
        line-height: 100%;
        font-style: normal;
        font-weight: 700;
        margin-top: -2%;

    }

    .hero-section-content-Text {

        width: 100%;
        height: 100%;
    }

    .hero-section-img-carousel {
        width: 100%;
        margin-top: 20%;
        height: 100%;
        flex-direction: column;
        display: flex;

    }

    .Hero-button-main {
        display: flex;
        flex-direction: column;
    }
}



@media only screen and (max-width: 280px) {
    .section-Hero {
        background-image: url(../assets/Hero-Bg.png);
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-repeat: no-repeat;


    }

    .hero-section-content-main {
        width: 80%;
        margin: auto;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .Hero-Section-head span {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 30px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: normal;
    }

    .Main-Head {
        color: #FFF;
        text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Teko', sans-serif;
        font-size: 80px;
        line-height: 100%;
        font-style: normal;
        font-weight: 700;
        margin-top: -2%;

    }

    .hero-section-content-Text {

        width: 100%;
        height: 100%;
    }

    .hero-section-img-carousel {
        width: 100%;
        margin-top: 20%;
        height: 100%;
        flex-direction: column;
        display: flex;

    }

    .Hero-button-main {
        display: flex;
        flex-direction: column;
    }
}