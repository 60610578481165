.centercont{
    background-color: #A72529;
    height: 100px;
    width: 85%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
}
.newslatter{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.left{
    width: 55%;
    text-align: left;
   display: flex;
   justify-content: center;
   align-items:left;
   flex-direction: column;
   padding-top: 20px;
   padding-left: 30px;
   color: white;
}
.Right{
    width: 45%;
    text-align: left;
   display: flex;
   justify-content: center;
   align-items:right;
  text-align: right;
   color: white;
  
}
.inpt::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    
  }
.inputsec{
    width:500px;
   background-color: #DC6367;
    height: 50px;
    margin-top: 25px;
text-align: left;
color: white;
border-radius: 12px;

padding-left: 10px !important;
flex-direction: row;
display: flex;
}
.inpt{
    background-color: transparent;
    border: none;
    margin-left: 10px;
}
.inpt ::placeholder{
  color: white;
    
}
.submitbutton{
height: 80%;
width: 90px;
border-radius: 12px;
background-color: white;
float:right;
margin-top: 5px;
color: #A72529;
display: flex;
justify-content: center;
align-items: center;
font-weight: 500;
margin-left: 37%;
}
@media only screen and (max-width: 600px) {
    .centercont{
        background-color: #A72529;
        height: 100%;
        width: 85%;
        border-radius: 12px;
        display: flex;
        flex-direction:column;
       
    }
    .left{
        width: 100%;
        text-align: left;
       display: flex;
       justify-content: center;
       align-items:center;
       flex-direction: column;
       padding-top: 20px;
       padding-left: 10px;
       color: white;
    }
    .inputsec{
        width:350px;
       background-color: #DC6367;
        height: 50px;
        margin-top: 25px;
    text-align: center;
    color: white;
    border-radius: 12px;
    
    padding-left: 10px !important;
    flex-direction: row;
    display: flex;
    }

.inputsec{
    width:100%;
   background-color: #DC6367;
    height: 50px;
    margin-top: 25px;
text-align: center;
color: white;
border-radius: 12px;

padding-left: 10px !important;
flex-direction: row;
display: flex;
}
.inpt{
    background-color: transparent;
    border: none;
    margin-left: 10px;
}
.inpt ::placeholder{
  color: white;
    
}
.newslatter{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.submitbutton{
height: 80%;
width: 90px;
border-radius: 12px;
background-color: white;
float:right;
margin-top: 5px;
color: #A72529;
display: flex;
justify-content: center;
align-items: center;
font-weight: 500;
margin-left: 0px;
}
  }