.New-Product {
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
    padding-top: 2rem;

}


.Product-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Product-head {
    color: #141414 !important;
    font-family: 'Teko', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;


}

.Product-head span {
    border-bottom: 6px solid #A72529;
    margin-bottom: 0 !important;
}

.New-product-items {
    width: 80%;
    height: 45%;

    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    margin: auto;
    margin-top: 3rem !important;
    justify-content: center;

}

.new-product-card {
    width: 25%;
    height: 100%;
    border: 2px solid #000;
    padding: 1rem;
}

.new-product-card-img img {
    width: 100%;
}

.N-P-Add-TO_CART_CONTENT {
    display: flex;
    width: 100%;

    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-tittle {
    color: #2C2C2C;
    font-family: 'Teko', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.new-product-add-to-cart button {
    width: 160px;
    height: 40px;
    background-color: #A72529;
    color: #FFF;
    font-family: 'Teko', sans-serif;
    font-size: 20px;
    border: none;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.pagination-button {

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4%;
    height: 40%;


}

.pagination-button {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.pagination-button button {
    display: flex;
    width: 40px;
    padding: 2px 0px 1px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000;
    background: #C0C0C0;
    color: #000;
    font-family: 'Teko', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Next-btn {
    display: flex;
    width: 131px;
    height: 42px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1rem !important;
    background: var(--primary-color, #A72529);
    outline: none;
    border: none;
    color: #FFF;
    font-family: 'Teko', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Media Quires  */
@media only screen and (max-width: 1024px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;


    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

}

@media only screen and (max-width: 375px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }
}

@media only screen and (max-width: 414px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }
}

@media only screen and (max-width: 820px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .new-product-add-to-cart button {
        width: 400px;
        height: 60px;
        background-color: #A72529;
        color: #FFF;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        border: none;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}

@media only screen and (max-width: 280px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .new-product-add-to-cart button {
        width: 160px;
        height: 40px;
        background-color: #A72529;
        color: #FFF;
        font-family: 'Teko', sans-serif;
        font-size: 20px;
        border: none;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }

    .Product-head {
        color: #141414;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        line-height: normal;


    }
}

@media only screen and (max-width: 414px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }
}

@media only screen and (max-width: 820px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .new-product-add-to-cart button {
        width: 400px;
        height: 60px;
        background-color: #A72529;
        color: #FFF;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        border: none;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}

@media only screen and (max-width: 412px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .new-product-add-to-cart button {
        width: 160px;
        height: 40px;
        background-color: #A72529;
        color: #FFF;
        font-family: 'Teko', sans-serif;
        font-size: 20px;
        border: none;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }

    .Product-head {
        color: #141414;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        line-height: normal;


    }
}

@media only screen and (max-width: 414px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }
}

@media only screen and (max-width: 820px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .new-product-add-to-cart button {
        width: 400px;
        height: 60px;
        background-color: #A72529;
        color: #FFF;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        border: none;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}

@media only screen and (max-width: 480px) {
    .New-Product {
        width: 100%;
        height: 100%;

    }

    .New-product-items {
        width: 80%;
        height: 45%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: auto;
        margin-top: 3rem !important;
        justify-content: center;

    }

    .new-product-card {
        width: 100%;
        height: 100%;
        border: 2px solid #000;
        padding: 1rem;
    }

    .new-product-tittle {
        color: #2C2C2C;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .new-product-add-to-cart button {
        width: 160px;
        height: 40px;
        background-color: #A72529;
        color: #FFF;
        font-family: 'Teko', sans-serif;
        font-size: 20px;
        border: none;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }

    .Product-head {
        color: #141414;
        font-family: 'Teko', sans-serif;
        font-size: 32px;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        line-height: normal;


    }
}







