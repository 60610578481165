.bannersec {
    background: url('../assets/banner.png');
    background-size: cover;
    height: 100%;
    padding-bottom: 40px;
}

.headerseccont {
    text-align: center;
    padding-top: 2rem;
    color: white;
}

.headerseccont h3 {

    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Teko', sans-serif;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;

    text-transform: uppercase;
}

.herocard {
    height: 230px;
    max-width: 265px;
    border: 3px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    margin-top: 40px;
}

.herocard h3 {
    color: white;
    margin-top: 5px;
}

.heroimg {
    height: 130px;
    width: 130px;
}

.author {
    background-color: white;

}

.authorhead {
    font-size: 40px;
    color: #A72529;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
}

.header_acount-paragh {
    color: #FFF;
    font-family: 'Teko', sans-serif;
    font-size: 36px;

    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.books {
    height: 100%;
    width: 100%;
}

.booksec {
    margin-top: 60px;
    margin-bottom: 40px;
}

.Summary {
    background-color: #F0F0F0;
    padding-bottom: 40px;
}

.sumhead {
    color: #A72529;
    font-size: 40px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
}

.sumpara {
    color: #181818;
    font-size: 20px;
    font-weight: 400;
}

.ul1 {
    text-align: left;
}

.secsec {
    margin-bottom: 60px;
    margin-top: 60px;
}

.technology {
    color: #A72529;
    margin-bottom: 30px;
    margin-top: 10px;
    font-weight: 500;
}

.technolog-paragraph {
    color: #181818;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    margin-left: -10%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 600px) {
    .books {
        height: 100%;
        width: 70%;
        padding-bottom: 20px;
    }

    .technolog-paragraph {
        color: #181818;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 24px;
        margin-left: 2%;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .technology {
        color: #A72529;
        margin-bottom: 30px;
        margin-top: 10px;
        font-weight: 500;
        text-align: center;
    }

    .imgr {
        display: none;
    }
}