@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap');



.main-categories {
    width: 100%;
    padding-bottom: 3.5rem;
    height: 100%;
    padding-top: 2rem;
    background-image: url(../assets/black-concrete-wall\ 1.png);

}

.categoreies-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.categories-head {
    color: #FFF;
    font-family: 'Teko', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;


}

.categories-head span {
    border-bottom: 6px solid #A72529;
    margin-bottom: 0 !important;
}

.categories-items {
    width: 90%;

    height: 37%;
    justify-content: center;
    margin-top: 3rem !important;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.categories-card {
    width: 22%;
    height: 100%;
    border: 4px solid #A72529;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.categoris-card-tittle h3 {
    color: #FFF;
    font-family: 'Teko', sans-serif;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.categories-card-img img {
    padding: 1rem;
    width: 100%;
    height: 200px;
}

/* Media Quires */
@media only screen and (max-width: 1280px) {

    .main-categories {
        width: 100%;
        padding: 2rem;
        height: 100%;
        background-image: url(../assets/black-concrete-wall\ 1.png);
        background-size: cover;
        background-attachment: fixed;
    }
}


/* Mobile Responsive */
@media only screen and (max-width: 480px) {

    .main-categories {
        width: 100%;
        padding: 2rem;
        height: 100%;
        background-image: url(../assets/black-concrete-wall\ 1.png);
        background-size: cover;
        background-attachment: fixed;
    }

    .categories-items {
        width: 100%;

        height: 37%;
        justify-content: center;
        margin-top: 3rem !important;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .categories-card {
        width: 100%;
        height: 100%;
        border: 4px solid #A72529;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 820px) {
    .main-categories {
        width: 100%;

        height: 100%;
        background-image: url(../assets/black-concrete-wall\ 1.png);
        background-size: cover;
        background-attachment: fixed;
    }

    .categories-items {
        width: 100%;

        height: 37%;
        align-items: center;
        margin-top: 3rem !important;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .categories-card {
        width: 50%;
        height: 100%;
        border: 4px solid #A72529;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 280px) {
    .main-categories {
        width: 100%;

        height: 100%;
        background-image: url(../assets/black-concrete-wall\ 1.png);
        background-size: cover;
        background-attachment: fixed;
    }

    .categories-items {
        max-width: 100%;

        height: 37%;
        align-items: center;
        margin-top: 3rem !important;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .categories-card {
        width: 100%;
        height: 100%;
        border: 4px solid #A72529;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }

    .categoreies-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    .main-categories {
        width: 100%;

        height: 100%;
        background-image: url(../assets/black-concrete-wall\ 1.png);
        background-size: cover;
        background-attachment: fixed;
    }

    .categories-items {
        max-width: 100%;

        height: 37%;
        align-items: center;
        margin-top: 3rem !important;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .categories-card {
        width: 100%;
        height: 100%;
        border: 4px solid #A72529;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 412px) {
    .main-categories {
        width: 100%;

        height: 100%;
        background-image: url(../assets/black-concrete-wall\ 1.png);
        background-size: cover;
        background-attachment: fixed;
    }

    .categories-items {
        max-width: 100%;

        height: 37%;
        align-items: center;
        margin-top: 3rem !important;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .categories-card {
        width: 100%;
        height: 100%;
        border: 4px solid #A72529;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }
}